import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
let officeSwiperWr = document.querySelectorAll('.office-swiper-wr');
officeSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 16,
      slidesPerView: 1,
      threshold: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        1024: {
          spaceBetween: 16,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      on:{
      afterInit: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        },
        resize: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        },
        slideChange: function() {
          if(window.innerWidth >= 767) {
            let previousContent = document.querySelector('.slider-content.active');

            if (previousContent) {
              previousContent.classList.remove('active');
            }

            let currentIndex = swiper.realIndex;
            let currentContent = el.querySelectorAll('.slider-content')[currentIndex];

            if (currentContent) {
              currentContent.classList.add('active');
            }
          }
        }
      }
    });

    swiperObserver(swiper);
  }
});

