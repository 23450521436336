import Swiper, {Autoplay, Navigation, Scrollbar} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let eventsSwiperWr = document.querySelectorAll('.events-swiper-wr');
eventsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scrollbar = el.querySelector('.swiper-scrollbar');
    let swiperButtons = el.querySelector('.swiper-buttons');    
    
    swiperButtons.classList.toggle('swiper-buttons-disabled', slidesCount <= 1);

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar, Autoplay],
      observer: true,
      observeParents: true,
      spaceBetween: 12,
      slidesPerView: 1,
      threshold: 10,
      scrollbar: {
        el: scrollbar,
        draggable: true,
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false
      },
      breakpoints: {
        768: {
          spaceBetween: 28,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 28,
        },
        1440: {
          slidesPerView: 1,
          spaceBetween: 28,
        }
      },
    });

    swiperObserver(swiper);
  }
});
