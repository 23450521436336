import Swiper, { Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
let footerSwiperWr = document.querySelectorAll('.footer-swiper-wr');
footerSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.buttons-next');
    let prevEl = el.querySelector('.buttons-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 24,
      slidesPerView: 1,
      threshold: 10,
      centeredSlides: true,
      allowTouchMove: false,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        1024: {
          spaceBetween: 24,
        },
      },
    });
  }
});