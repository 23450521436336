if(document.querySelector('.accordion__item')) {
  const accordionElArr = document.querySelectorAll('.accordion');
  accordionElArr.forEach(accordionEl => {
    const accordionItems = accordionEl.querySelectorAll('.accordion__item');
    accordionItems[0].classList.add('active');

    accordionItems.forEach((item) => {
      item.addEventListener('click', () => {
        for (let i = 0; i < accordionItems.length; i++) {
          accordionItems[i].classList.remove('active');
        }
        item.classList.add('active');
      });
    });
  })
}
