window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();


// range

// const sliderEl = document.querySelector("#range")
// const sliderValue = document.querySelector(".value")

// if(sliderEl) {
//   const calcRangeValue = (event, element) => {
//     const tempSliderValue = event?.target?.value || element?.value;

//     sliderValue.textContent = tempSliderValue;

//     const progress = (tempSliderValue / sliderEl.max) * 100;
//     console.log(tempSliderValue);
//     console.log(sliderEl.max);


//     sliderEl.style.background = `linear-gradient(to right, #855BF7 ${progress}%, #fff ${progress}%)`;
//   }

//   sliderEl?.addEventListener("input", (event) => calcRangeValue(event, false))

//   calcRangeValue(false, sliderEl)
// }


// progressScript()
