import Parallax from 'parallax-js';

if (document.querySelector('[data-parallax-el]') && window.innerWidth >= 1025) {
  const parallaxContainer = document.querySelectorAll('[data-parallax-el]');
  parallaxContainer.forEach((elem) => {
    let parallaxInstance = new Parallax(elem);
    parallaxInstance.enable();
  });
}

if (document.querySelector('.anime-motion-animation')) {
  const animeMotionAnimElArr = document.querySelectorAll(
      '.anime-motion-animation'
  );
  animeMotionAnimElArr.forEach((animeMotionAnimEl) => {
    const defaultSpeed = 4000;
    const originalSpeed =
        Number(parseFloat(animeMotionAnimEl.getAttribute('data-anime-speed'))) ||
        defaultSpeed;

    const pathArr = animeMotionAnimEl.querySelectorAll('svg path');
    pathArr.forEach((path) => {
      const petaAnimation = anime({
        targets: path,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: originalSpeed,
        delay: function (el, i) {
          return i * 1 + 100;
        },
        direction: 'normal',
        loop: false,
      });
      petaAnimation.pause();

      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setTimeout(() => {
                  petaAnimation.play();
                  animeMotionAnimEl.classList.add('animated');
                }, 600);
                observer.unobserve(animeMotionAnimEl);
              }
            });
          },
          { threshold: 0, rootMargin: '0% 0% -10% 0%' }
      );

      observer.observe(animeMotionAnimEl);
    })
  });
}
