// const ranges = document.querySelectorAll('. ');

// if(ranges.length) {
//   ranges.forEach(range => {
//     const labelValue = range.querySelector('label span');
//     const rangeInput = range.querySelector('input');
    
//     labelValue.textContent = rangeInput.value;
//     rangeInput.addEventListener('input', () => {
//       labelValue.textContent = rangeInput.value;
//     });
//   });
// }

const doubleRangeSliderInputs = () => {
  const doubleRange = document.getElementsByClassName("double-range-inputs")[0];
  if (doubleRange) {
    const slider = doubleRange.querySelector("#double-range-inputs");
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit || "£";
    const step = +slider.dataset.step;
    const inputs = doubleRange.querySelectorAll(".double-range-input");
    const units = doubleRange.querySelectorAll(".unit");
    const startValueMin = +inputs[0].value;
    const startValueMax = +inputs[1].value;

    noUiSlider.create(slider, {
      start: [startValueMin, startValueMax],
      connect: true,
      margin: 10,
      step: step,
      range: {
        min: min,
        max: max,
      },
    });

    units.forEach((elem) => {
      elem.textContent = unit;
    });

    slider.noUiSlider.on("update", function (values, handle) {
      inputs[handle].value = Math.round(values[handle]);
      console.log(inputs[handle]);
      console.log(inputs[handle].id);
      if(inputs[handle].id === 'double-range-value-min') {
        document.querySelector('.range-value-min span.value').innerHTML = inputs[handle].value;
      } else {
        document.querySelector('.range-value-max span.value').innerHTML = inputs[handle].value;
      }
        
    });

    inputs.forEach((elem, i) => {
      elem.addEventListener("input", function () {
        elem.value = elem.value.replace(/[^\d.]/g, "");
        slider.noUiSlider.set(this.value);
        console.log(elem);
        
      });
    });
  }
};
doubleRangeSliderInputs();

