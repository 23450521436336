import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';
let weOfferSwiperWr = document.querySelectorAll('.we-offer-swiper-wr');
weOfferSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    swiperOnResize('(max-width: 1023px)', swiperEl,
        {
          modules: [Autoplay, Navigation],
          slidesPerView: 1,
          spaceBetween: 20,
          speed: 800,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          breakpoints: {
            500: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          },
          navigation: {
            prevEl: prevEl,
            nextEl: nextEl,
          },
        },
    );
  }
});