import './utils/vh.utils.js';
import './utils/animate-on-view.utils'
import './utils/parallax-on-view.utils.js'

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/meta-select.component.js';
import './components/player.component.js';
import './components/mentorship.component.js';
import './components/accordion.component.js';

import './libs/countUp.lib.js';
import './components/header.component.js'
import './components/vertical-accordion.component.js'
import './components/jobs-search.component.js';
import './components/animations.component.js';
import './components/meta-range.component.js';
import './components/marquee.component.js';

import './components/swiper-partners.component.js';
import './components/swiper-jobs.component.js';
import './components/swiper-specialties.component.js';
import './components/swiper-our-team.component.js';
import './components/swiper-case-study.component.js';
import './components/swiper-relationships.component.js';
import './components/swiper-events.component.js';
import './components/swiper-videos.component.js';
import './components/swiper-our-process.component.js';
import './components/swiper-testimonials.component.js';
import './components/swiper-we-offer.component.js';
import './components/swiper-what-we-do.component.js';
import './components/swiper-footer.component.js';
import './components/smooth-scrollbar.component.js';
import './components/office-swiper.component.js';
import './components/sw-videos-6.component.js';
import './components/sw-videos-4.component.js';
import './components/sw-specialties-3.component.js';
import './components/sw-specialties-4.component.js';
import './components/sw-specialties-5.component.js';
import './components/sw-we-offer-2.component.js';
import './components/sw-testimonials-3.component.js';
import './components/sw-testimonials-4.component.js';
import './components/sw-office-5-slider.component.js';
import './components/sw-office-4-slider.component.js';
/*import './components/vertical-tab-accordion.component.js';*/

window.closePopup = function closePopup() {
  document.querySelector('#popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
}

import Scrollbar from 'smooth-scrollbar';

window.scrollInitFunc = function scrollInitFunc() {
  if (document.querySelector('[data-scroll]')) {
    const scrollElArr = document.querySelectorAll('[data-scroll]');
    scrollElArr.forEach((el) => {
      if (!el.classList.contains('is-scroll-init')) {
        el.classList.add('is-scroll-init');

        const startScrollWidth = parseInt(
          el.getAttribute('data-start-scroll'),
          10
        );

        if (!startScrollWidth || window.innerWidth <= startScrollWidth) {
          const scrollbar = Scrollbar.init(el, {
            alwaysShowTracks: true,
            damping: 0.1,
            renderByPixels: true,
          });

          scrollbar.addListener(({ offset }) => {
            if (offset.x !== 0) {
              scrollbar.setPosition(0, offset.y);
            }
          });
        }
      }
    });
  }
};

scrollInitFunc();

document.querySelectorAll('ol').forEach((ol) => {
  ol.querySelectorAll('li').forEach((li, index) => {
    const span = document.createElement('span');
    span.textContent = index + 1;
    li.prepend(span);
  });
});

document.addEventListener("DOMContentLoaded", () => {
  const cards = document.querySelectorAll('.events-card__info');

  let maxHeight = 0;
  cards.forEach(card => {
    const cardHeight = card.offsetHeight;
    if (cardHeight > maxHeight) {
      maxHeight = cardHeight;
    }
  });

  cards.forEach(card => {
    if (card.offsetHeight < maxHeight) {
      card.style.minHeight = `${maxHeight}px`;
    }
  });
});
