if(document.querySelector('.mentorship-section__tabs')) {
  const mentorshipTabsElArr = document.querySelectorAll('.mentorship-section__tabs');
  mentorshipTabsElArr.forEach(tabsEl => {
    const tabHeaders = tabsEl.querySelectorAll('.tab-header');
    const tabContents = tabsEl.querySelectorAll('.tabs-content');

    tabHeaders[0].classList.add('active');
    tabContents[0].classList.add('active');

    tabHeaders.forEach((headBtn, idx) => {
      headBtn.addEventListener('click', () => {
        for (let i = 0; i < tabHeaders.length; i++) {
          tabHeaders[i].classList.remove('active');
          tabContents[i].classList.remove('active');
        }
        headBtn.classList.add('active');
        tabContents[idx].classList.add('active');
      })
    })
  })
}