/*
const verticalAccordion = document.getElementsByClassName("vertical-accordion")[0];
const verticalACcordionTab = document.getElementsByClassName('.advantages-section__tab')[0];
if (verticalAccordion) {
  const verticalAccordionItemsArr = verticalAccordion.querySelectorAll(".vertical-accordion__item");

  verticalAccordionItemsArr.forEach((item) => {
    item.addEventListener("click", () => {
      const activeItem = document.querySelector(".vertical-accordion__item.active");
      if (activeItem) {
        activeItem.classList.remove("active");
      }
      item.classList.add("active");

      if (window.innerWidth <= 540) {
        setTimeout(() => {
          const itemTop = item.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: itemTop - 90,
            behavior: "smooth",
          });
        }, 300);
      }
    });
  });
}

document.addEventListener("DOMContentLoaded", function () {
  const accordionItems = document.querySelectorAll('.vertical-accordion__item');

  if (window.innerWidth > 769) {
    accordionItems.forEach(item => {
      const title = item.querySelector('.title');

      if (title) {
        const itemHeight = item.offsetHeight;
        title.style.width = `${itemHeight - 108}px`;
      }
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const accordionItems = document.querySelectorAll('.vertical-accordion__item');

  if (window.innerWidth > 769 && accordionItems > 3) {
    accordionItems.forEach(item => {
      if (item.classList.contains('active')) {
        const itemWidth = item.offsetWidth;
        const infoBlock = item.querySelector('.info-block');

        if (infoBlock) {
          const widthPercentage = (itemWidth / item.parentElement.offsetWidth) * 100;
          infoBlock.style.width = `${widthPercentage}%`;
        }
      }
    });
  }
});

*/

document.querySelectorAll(".vertical-accordion").forEach(verticalAccordion => {
  const verticalAccordionItemsArr = verticalAccordion.querySelectorAll(".vertical-accordion__item");

  verticalAccordionItemsArr.forEach((item) => {
    item.addEventListener("click", () => {
      const activeItem = verticalAccordion.querySelector(".vertical-accordion__item.active");
      if (activeItem) {
        activeItem.classList.remove("active");
      }
      item.classList.add("active");

      if (window.innerWidth <= 540) {
        setTimeout(() => {
          const itemTop = item.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: itemTop - 90,
            behavior: "smooth",
          });
        }, 300);
      }
    });
  });
});

document.querySelectorAll(".vertical-accordion").forEach(verticalAccordion => {
  const accordionItems = verticalAccordion.querySelectorAll(".vertical-accordion__item");

  if (window.innerWidth > 769) {
    accordionItems.forEach(item => {
      const title = item.querySelector(".title");

      if (title) {
        const itemHeight = item.offsetHeight;
        title.style.width = `${itemHeight - 108}px`;
      }
    });
  }
});

document.querySelectorAll(".vertical-accordion").forEach(verticalAccordion => {
  const accordionItems = verticalAccordion.querySelectorAll(".vertical-accordion__item");

  if (window.innerWidth > 769 && accordionItems.length > 3) {
    accordionItems.forEach(item => {
      if (item.classList.contains("active")) {
        const itemWidth = item.offsetWidth;
        const infoBlock = item.querySelector(".info-block");

        if (infoBlock) {
          const widthPercentage = (itemWidth / verticalAccordion.offsetWidth) * 100;
          infoBlock.style.width = `${widthPercentage}%`;
        }
      }
    });
  }
});

if(document.getElementsByClassName('advantages-section__tabs'[0])) {
  const advantagesTabsElArr = document.querySelectorAll('.advantages-section__tabs');
  advantagesTabsElArr.forEach(tabsEl => {
    const advantagesTabsArr = tabsEl.querySelectorAll('.advantages-section__tab');
    const parentSection = tabsEl.parentElement.parentElement;
    const verticalItemsArr = parentSection.querySelectorAll('.vertical-accordion__item');
    console.log(verticalItemsArr)

    advantagesTabsArr.forEach((tab, idx) => {
      tab.addEventListener('click', () => {

        for (let i = 0; i < advantagesTabsArr.length; i++) {
          advantagesTabsArr[i].classList.remove('active');
          verticalItemsArr[i].classList.remove('active');
        }

        tab.classList.add('active');
        verticalItemsArr[idx].classList.add('active');
      })
    })

    verticalItemsArr.forEach((verticalTab, index) => {
      verticalTab.addEventListener('click', () => {
        for (let i = 0; i < advantagesTabsArr.length; i++) {
          advantagesTabsArr[i].classList.remove('active');
          verticalItemsArr[i].classList.remove('active');
        }

        verticalTab.classList.add('active');
        advantagesTabsArr[index].classList.add('active');
      })
    })
  })
}



