import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
let specialtiesSwiper3Wr = document.querySelectorAll('.specialties-swiper-3-wr');
specialtiesSwiper3Wr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 24,
      slidesPerView: 1,
      threshold: 10,
      effect: 'creative',
      creativeEffect: {
        limitProgress: 2,
        prev: {
          translate: [0, 0, -320],
          opacity: 0,
        },
        next: {
          translate: ['calc(100% + clamp(20px, -3.57143vw - 24.5714px, 30px))', 0, 0],
          opacity: 1,
          effect: 'scale',
          scale: 1,
        },
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        540: {
          slidesPerView: 1,
        },
        1024: {
          spaceBetween: 28,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      on:{
      afterInit: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        },
        resize: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        }
      }
    });
    swiperObserver(swiper);
  }
});