import Swiper, { Autoplay, Navigation, EffectCreative, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
let videos4SwiperWr = document.querySelectorAll('.videos-swiper-4-wr');
videos4SwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination, EffectCreative],
      observer: true,
      observeParents: true,
      effect: 'creative',
      creativeEffect: {
        limitProgress: 2,
        prev: {
          translate: [0, 0, -320],
          opacity: 0,
        },
        next: {
          translate: ['100%', 0, 0],
          opacity: 1,
          effect: 'scale',
          scale: 0.85,
        },
      },
      translate: ['calc(100% + clamp(20px, -3.57143vw - 24.5714px, 30px))', 0, 0],
      spaceBetween: 28,
      slidesPerView: 1,
      threshold: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        1024: {
          spaceBetween: 24,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      on:{
      afterInit: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        },
        resize: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        }
      }
    });
    swiperObserver(swiper);
  }
});