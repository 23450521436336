import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperMarquee } from '../utils/swiperMarquee.utils.js';

let partnersSwiperWr = document.querySelectorAll('.partners-slider-wr');
partnersSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    let slides = Array.from(wrapper.children);
    slides.sort(() => Math.random() - 0.5);
    slides.forEach((slide) => wrapper.appendChild(slide));

    let swiper = new Swiper(swiperEl, {
      observer: true,
      observeParents: true,
      spaceBetween: 0,
      slidesPerView: 'auto',
      threshold: 10,
      loop: slidesCount > 5,
      loopedSlides: slidesCount > 5 ? slidesCount * 2 : false,
    });

    swiperMarquee(swiper, {
      speed: window.innerWidth >= 768 ? 0.77 : 0.5,
    });
  }
});
