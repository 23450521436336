import Swiper, {Navigation, Scrollbar} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let videosSwiperWr = document.querySelectorAll('.videos-swiper-wr');
videosSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scroll = el.querySelector('.swiper-scrollbar');

    /*player.on('play', function () {
      parentEl.classList.add('is-played');
    });

    player.on('pause', function () {
      parentEl.classList.remove('is-played');
    });*/
    
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar],
      observer: true,
      observeParents: true,
      spaceBetween: 12,
      slidesPerView: 1,
      threshold: 10,
      scrollbar: {
        el: scroll,
        draggable: true,
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        768: {
          spaceBetween: 28,
        },
        1024: {
          slidesPerView: 3,
        },
        1440: {
          slidesPerView: 3,
          spaceBetween: 28,
        }
      },
    });
  }
});
