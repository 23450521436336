import Swiper, { Autoplay, Navigation, EffectCreative, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
let officeSwiper5Wr = document.querySelectorAll('.office-swiper-5-wr');
officeSwiper5Wr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative, Pagination],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 16,
      pagination: {
        el: el.querySelector(".swiper-pagination"),
        type: "fraction",
      },
      effect: 'creative',
      creativeEffect: {
        limitProgress: 2,
        prev: {
          translate: [0, 0, -320],
          opacity: 0,
        },
        next: {
          translate: ['calc(100% + 16px)', 0, 0],
          effect: 'scale',
          scale: 1,
          opacity: 0.4,
        },
      },
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        1024: {
          spaceBetween: 16,
        },
      },
      autoplay: {
        delay: 400044,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      on:{
      afterInit: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        },
        resize: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        }
      }
    });
    swiperObserver(swiper);
  }
});