import { CountUp } from 'countup.js';

const counts = Array.from(document.getElementsByClassName('count-up'));

if (counts) {
  const defaultOptions = {
    separator: ',',
    enableScrollSpy: true,
    scrollSpyRunOnce: true,
  };

  let idNumber = 1;

  counts.forEach((count) => {
    const id = `count-up-${idNumber}`,
      value = parseFloat(count.innerHTML);

    let optionsFromDataAttr = { ...count.dataset };

    for (const key in optionsFromDataAttr) {
      if (optionsFromDataAttr[key] === '') {
        optionsFromDataAttr[key] = true;
      }
    }

    count.id = id;

    const countUp = new CountUp(
      id,
      value,
      Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
    );

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          countUp.start(); // Запуск анимации
          if (defaultOptions.scrollSpyRunOnce) {
            observer.unobserve(count);
          }
        }
      });
    }, { threshold: 0.7 });

    observer.observe(count);
    idNumber++;
  });
}
