import Swiper, {Navigation, Scrollbar, Autoplay, Pagination} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let caseStudySwiperWr = document.querySelectorAll('.case-swiper-wr');
caseStudySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scroll = el.querySelector('.swiper-scrollbar');

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar, Autoplay, Pagination],
      observer: true,
      observeParents: true,
      spaceBetween: 12,
      slidesPerView: 1,
      threshold: 10,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      scrollbar: {
        el: scroll,
        draggable: true,
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      breakpoints: {
        420: {
          slidesPerView: 1,
        },
        580: {
          spaceBetween: 16,
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
        950: {
          slidesPerView: 1,
        },
        1024: {
          spaceBetween: 82,
          slidesPerView: 1,
        },
        1200: {
          spaceBetween: 112,
          slidesPerView: 1,
        },
        1440: {
          spaceBetween: 112,
          slidesPerView: 1,
        },
      },
    });

    swiperObserver(swiper);
  }
});
